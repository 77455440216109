/* eslint-disable no-param-reassign */
import log from 'loglevel';
import axios from 'axios';
import store from '../store.js'; 
const baseAPIPath = process.env.REACT_APP_ENV && process.env.REACT_APP_ENV == "prod" ? "https://beta.byvr.ai" : "";

const state = store.getState();
const userData = state.user; 

const ai_dev_response = "To determine the presence of Indian groceries nearby the property located at 1291 Riesling Trail, Sunnyvale, CA, you can use various online mapping services or local business directories. While the details provided do not specifically mention nearby Indian grocery stores, Sunnyvale is a diverse city with plenty of Indian communities, and it is likely that there are Indian grocery stores in the area. \
        \
        You might consider looking for grocery stores such as:\
        \
        - **Indian Mart** \
        - **Bay Leaf Indian Grocery**\
        - **Patel Brothers**\
        - **Shreeji Indian Grocery**\
        \
        These stores typically carry a variety of Indian spices, snacks, fresh produce, and other goods that may be of interest. For the most accurate and updated information, checking Google Maps or Yelp with the query \"Indian grocery stores near me\" will yield specific results for your location."


const ai_location_summary = "This house is located in Sunnyvale, California, and offers several features that are beneficial for raising kids:\n\n1. **Neighborhood and Schools**: The house is in a highly sought-after area in Silicon Valley, which is generally regarded as family-friendly. There are several schools nearby, including Cumberland Elementary School, Sunnyvale Middle School, and Fremont High School, with ratings of 7, 6, and 7 respectively, indicating a decent quality of education for children. Proximity to schools is often a significant factor for families.\n\n2. **Size and Layout**: The home has 4 bedrooms and 3 bathrooms, providing ample space for a family. The layout includes a ground-floor bedroom that can serve as a playroom or an office, and an upstairs that features a bonus loft office area, potentially useful for studying or play.\n\n3. **Outdoor Space**: The private backyard offers a space for outdoor activities, such as playing or hosting gatherings, which can be important for family life. It’s described as a tranquil oasis, allowing families to unwind outdoors.\n\n4. **Community Amenities**: While specific community features are not detailed, living in a desirable neighborhood often comes with parks and recreational facilities nearby, which are important for children’s activities.\n\n5. **Safety and"

/**
 * This function is used for the adding a delay in the developmode while returning the response
 * @param {*} ms 
 * @returns 
 */
function delay(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

/**
 * 
 * @param {*} user_question 
 * @param {*} google_place_id 
 * @returns 
 */
export const get_house_query_answer = async function (user_question, selectedAddress, google_place_id) {
    const qna_url = `${baseAPIPath}/v1/api/ai/house/qna?address= ${selectedAddress}&google_place_id=${google_place_id}`

    const state = store.getState();
    const userData = state.user; 

    const email = userData.userData?.user_details?.email??null;

    const headers = {
        'Content-Type': 'application/json',
        'email': email
    };
    try {
        const response = await axios.post(qna_url, { question: user_question }, { headers });
        if (response.status !== 200) {
            throw new Error('Network response was not ok');
        }
        return response.data.answer;
    } catch (error) {
        log.error(error);
        if (process.env.NODE_ENV === 'development') {
            await delay(5000);
            return ai_location_summary;
        }
        return 'Network response was not ok';
    }
};


/**
 * 
 * @param {*} user_question 
 * @param {*} google_place_id 
 * @returns 
 */
export const get_location_summary = async function ( selectedAddress, google_place_id) {
    const ai_summary_url = `${baseAPIPath}/v1/api/ai/location/summary?address=${selectedAddress}&google_place_id=${google_place_id}`

    const state = store.getState();
    const userData = state.user; 

    const email = userData.userData?.user_details?.email??null;

    const headers = {
        'Content-Type': 'application/json',
        'email': email
    };
    try {
        const response = await axios.post(ai_summary_url , {}, { headers });
        if (response.status !== 200) {
            throw new Error('Network response was not ok');
        }
        return response.data.answer;
    } catch (error) {
        log.error(error);
        if (process.env.NODE_ENV === 'development') {
            await delay(5000);
            return ai_dev_response;
        }
        return 'Network response was not ok';
    }
};


/**
 * 
 * @param {*} user_question 
 * @param {*} google_place_id 
 * @returns 
 */
export const get_house_summary = async function ( selectedAddress, google_place_id) {
    const ai_summary_url = `${baseAPIPath}/v1/api/ai/location/summary?address=${selectedAddress}&google_place_id=${google_place_id}`

    const state = store.getState();
    const userData = state.user; 

    const email = userData.userData?.user_details?.email??null;

    const headers = {
        'Content-Type': 'application/json',
        'email': email
    };
    try {
        const response = await axios.post(ai_summary_url , {}, { headers });
        if (response.status !== 200) {
            throw new Error('Network response was not ok');
        }
        return response.data.answer;
    } catch (error) {
        log.error(error);
        if (process.env.NODE_ENV === 'development') {
            await delay(5000);
            return ai_dev_response;
        }
        return 'Network response was not ok';
    }
};
const aiService = {
    get_house_query_answer,
    get_location_summary,
    get_house_summary
};

export default aiService;