// import logo from './BackGround.png';
import './App.css';
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { Provider, useDispatch } from 'react-redux';
import MenuAppBar from './Components/ByvrMenuAppBar';
import authServices from './Services/authService';
import userService from './Services/userService';
import ByvrHome from './Pages/ByvrHome';
import ByvrDashboard from './Pages/ByvrDashboard';
import ByvrUserProfile from './Pages/ByvrUserProfile';
import ByvrLoginCard from './Pages/ByvrLoginPage';
import ByvrDashboardBulk from './Pages/ByvrDashboardBulk';
import logger from './utils/logger';
import { login } from './features/auth/authSlice';
import store from './store';
import { useSelector } from 'react-redux';
import { setUserData, clearUserData } from './features/user/userSlice';
import { compactTheme } from './theme/byvrDefaultTheme';
import { CustomThemeProvider, useThemeContext } from './theme/byvrThemeContext';
import CssBaseline from '@mui/material/CssBaseline';

function Routes_Content() {
    const userData = useSelector((state) => state.user.userData);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (!userData) {
            const token = localStorage.getItem('jwtToken');
            if (token) {
                try {
                    const decodedToken = JSON.parse(atob(token.split('.')[1]));
                    const email = decodedToken.email;
                    userService.getUserByEmail(email)
                        .then(user_data => {
                            console.log(user_data,"user_data");
                            dispatch(setUserData(user_data));
                            dispatch(login());
                        })
                        .catch(error => {
                            logger.error('Error fetching user data:', error);
                        });
                } catch (error) {
                    logger.error('Error decoding token:', error);
                }
            }
        }
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if (code) {
            authServices.exchangeCodeForToken(code)
                .then((resp) => {
                    logger.debug(resp);
                    authServices.storeUserInfoInSession(resp);
                    authServices.createJWTToken(resp.user_details.email);
                    userService.getUserByEmail(resp.user_details.email)
                    .then(user_data => {
                            dispatch(setUserData(resp));
                            dispatch(login());
                        })
                    .catch(error => {
                        authServices.createUser(resp)
                        dispatch(setUserData(resp));
                        dispatch(login());
                        navigate('/profile');
                    })
                });
        } else {
            authServices.getUserInfoInSession()
                .then((resp) => {
                    logger.debug(resp);
                    if (resp.token) {
                        dispatch(setUserData(resp));
                        dispatch(login());
                    }
                })
                .catch((err) => {
                    logger.error(err);
                });
        }
    }, [dispatch, navigate, userData]);

    const handleClearUserData = () => {
        dispatch(clearUserData());
    };

    return (
        <>
            <MenuAppBar userData={userData} clearUserData={handleClearUserData} />
            <Routes>
                <Route exact path="/" element={<ByvrHome userData={userData} />} />
                <Route exact path="/login" element={<ByvrLoginCard />} />
                <Route exact path="/analytics" element={<ByvrDashboard />} />
                <Route exact path="/bulk-analytics" element={<ByvrDashboardBulk />} />
                <Route path="/profile" element={<ByvrUserProfile />} />
                <Route path="*" element={<ByvrHome userData={userData} />} />
            </Routes>
        </>
    );
}

function AppContent() {
    return (
        <Router>
            <Routes_Content />
        </Router>
    );
}

function App() {
    return (
        <Provider store={store}>
            <CustomThemeProvider>
                <CssBaseline />
                <AppContent />
            </CustomThemeProvider>
        </Provider>
    );
}

export default App;
