import React, { useState, useEffect, useRef } from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem, TextField } from '@mui/material';
const baseAPIPath = process.env.REACT_APP_ENV && process.env.REACT_APP_ENV == "prod" ? "https://beta.byvr.ai" : "";

function FinancialSetting() {
    const [formData, setFormData] = useState({
        ticker: '',
        annualReturn: ''
    });
    const [tickers, setTickers] = useState([]);

    const handleFieldChange = (field, value) => {
        setFormData(prevState => ({
            ...prevState,
            [field]: value
        }));
    };

    const fetchAnnualReturn = (ticker) => {
        const selectedTicker = tickers.find(t => t.ticker === ticker);
        if (selectedTicker) {
            handleFieldChange('annualReturn', selectedTicker.annualized_return);
        }
    };

    const fetchTickers = async () => {
        try {
            const response = await fetch(`${baseAPIPath}/v1/api/analytics/stock-returns`);
            const data = await response.json();
            setTickers(data.data);
        } catch (error) {
            console.error('Error fetching tickers:', error);
        }
    };

    useEffect(() => {
        fetchTickers();
    }, []);

    useEffect(() => {
        if (formData.ticker) {
            fetchAnnualReturn(formData.ticker);
        }
    }, [formData.ticker]);

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
                <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel id="ticker-label">Ticker</InputLabel>
                    <Select
                        labelId="ticker-label"
                        id="ticker"
                        value={formData.ticker}
                        onChange={(e) => handleFieldChange('ticker', e.target.value)}
                        label="Ticker"
                    >
                        {tickers.map((ticker) => (
                            <MenuItem key={ticker.ticker} value={ticker.ticker}>
                                {ticker.ticker}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
                <TextField
                    fullWidth
                    variant="outlined"
                    size="small"
                    label="Annual Return"
                    value={formData.annualReturn}
                    onChange={(e) => handleFieldChange('annualReturn', e.target.value)}
                />
            </Grid>
        </Grid>
    );
}

export default FinancialSetting;
