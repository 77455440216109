import React, { useState, useEffect, useRef } from 'react';
import { Card } from '@mui/material';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import './ByvrHouseMapView.css'; // Ensure this file is imported correctly

// Define a custom icon for the markers
const customIcon = new L.Icon({
    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
    shadowSize: [41, 41],
});

const MapViewAdjuster = ({ addresses }) => {
    const map = useMap();

    useEffect(() => {
        if (!map || addresses.length === 0) return;
        if (addresses.length > 0) {
            // Calculate average latitude and longitude
            const avgLat =
                addresses.reduce((sum, addr) => sum + addr.latitude, 0) / addresses.length;
            const avgLng =
                addresses.reduce((sum, addr) => sum + addr.longitude, 0) / addresses.length;

            // Adjust map center
            map.setView([avgLat, avgLng], 14); // Zoom in closer to center
        }
    }, [addresses, map]);

    return null;
};

function ByvrHouseMapView({ addresses, onAddressClick }) {
    return (
        <Card className="map-card">
            <MapContainer
                className="map-container"
                center={[0, 0]} 
                zoom={10}
            >
                <TileLayer
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                />

                <MapViewAdjuster addresses={addresses} />

                {addresses
                    .filter(({ latitude, longitude }) => latitude !== null && longitude !== null)
                    .map(({ id, latitude, longitude, address }) => (
                    <Marker
                        key={id}
                        position={[latitude, longitude]}
                        icon={customIcon}
                        eventHandlers={{
                            click: () => onAddressClick({ id, latitude, longitude, address }),
                        }}
                    >
                        <Popup>{address}</Popup>
                    </Marker>
                ))}
            </MapContainer>
        </Card>
    );
}

export default ByvrHouseMapView;
