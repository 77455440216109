import React, { useState, useEffect } from 'react';
import { Grid, TextField, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { housedetails } from '../../features/house/houseSlice';
import SliderInputField from '../SliderInputField';
import { Padding } from '@mui/icons-material';


function RealStateSetting({showbreakdown}) {
    const dispatch = useDispatch();
    const houseDetail = useSelector((state) => state.house.houseDetail);

    const [formData, setFormData] = useState({
        house_price: 0,
        down_payment_percent: 0,
        property_tax_rate: 0,
        insurance_cost: 0,
        buying_closing_costs: 0,
        condo_fees: 0,
        additional_monthly_costs: 0,
        house_price_appreciation: 0,
        interest_rate: 0,
        loan_term :15 ,
        hold_for_years: 20,
        rate_buydown_pay:'',
        rate_buydown_reduction:0
    });

    useEffect(() => {
        if (houseDetail) {
            setFormData((prev) => ({
                ...prev,
                house_price: houseDetail.house_price || '',
                down_payment_percent: houseDetail.down_payment_percent || '',
                property_tax_rate: houseDetail.property_tax_rate || '',
                insurance_cost: houseDetail.insurance_cost || houseDetail.house_price / 200,
                buying_closing_costs: houseDetail.buying_closing_costs || '',
                condo_fees: houseDetail.HOA || '',
                additional_monthly_costs: houseDetail.additional_monthly_costs || '',
                house_price_appreciation: houseDetail.house_price_appreciation || 4,
                interest_rate: houseDetail.interest_rate || '',
                loan_term : houseDetail.loan_term || 15,
                hold_for_years: houseDetail.hold_for_years || 20,
            }));
        }
    }, [houseDetail]);

    const handleFieldChange = (field, value , max=100) => {
        let parsedValue;
        if (field === 'house_price_appreciation' || field === 'down_payment_percent' ||
            field === 'property_tax_rate' ||
            field === 'buying_closing_costs' || field === 'interest_rate' || field === 'rate_buydown_reduction') {
            parsedValue = value === '' ? 0 : Math.max(0, Math.min(max, parseFloat(value)));
        } else if(field === 'insurance_cost') {
            parsedValue = value === '' ? 0 : (parseFloat(value)>parseFloat(max)?max:value);
        }else {
            parsedValue = value === '' ? 0 : parseFloat(value);
        }
        setFormData((prev) => {
            let updatedData = { ...prev, [field]: parsedValue };
            let rateBuydownPay = parseFloat(updatedData.rate_buydown_pay);
            let rateBuydownReduction = parseFloat(updatedData.rate_buydown_reduction);
            
            if (!isNaN(rateBuydownPay) && rateBuydownPay > 0) {
                updatedData.house_price = parseFloat(updatedData.house_price) + rateBuydownPay;
            }
            if (!isNaN(rateBuydownReduction) && rateBuydownReduction > 0 && rateBuydownReduction < updatedData.interest_rate) {
                updatedData.interest_rate -= rateBuydownReduction;
            }
            return updatedData;
        });
        const updatedHouseData = { ...houseDetail, [field]: parsedValue };
        dispatch(housedetails(updatedHouseData));
    };

    const handleLoanTermChange = async (value) => {
        const selectedTerm = value;
        // handleFieldChange("loan_term", selectedTerm); 

        try {
            // setLoading(true); 
            // setError(null); 
            setFormData((prevData) => ({
                ...prevData,
                loan_term: selectedTerm,
            }));

            
            const response = await fetch(`/v1/api/analytics/mortgage-rates?ratetype=Fixed${selectedTerm}Year`);

            if (!response.ok) {
                throw new Error("Failed to fetch interest rate.");
            }

            const data = await response.json();

            if (data.success && data.data.length > 0) {
                
                const interestRate = data.data[0].ratevalue;
                setFormData((prevData) => ({
                    ...prevData,
                    interest_rate: interestRate,
                }));
            } else {
                // setError("No interest rate found for the selected loan term.");
            }
        } catch (err) {
            // setError("Failed to fetch interest rate. Please try again.");
            console.error("API Error:", err);
        } finally {
            // setLoading(false); 
        }
    };

    return (
        <div>
            <Grid container spacing={4}>
                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Home Price"
                        variant="outlined"
                        size="small"
                        value={formData.house_price}
                        onChange={(e) => handleFieldChange('house_price', e.target.value)}
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel>Loan Term (Years)</InputLabel>
                        <Select
                            value={formData.loan_term}
                            onChange={(e) => handleLoanTermChange( e.target.value)}
                            label="Duration"
                        >
                            <MenuItem value={10}>10 Years Fixed</MenuItem>
                            <MenuItem value={15}>15 Years Fixed</MenuItem>
                            <MenuItem value={20}>20 Years Fixed</MenuItem>
                            <MenuItem value={30}>30 Years Fixed</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <SliderInputField
                        label="Down Payment(%)"
                        value={formData.down_payment_percent}
                        onChange={(newValue) => handleFieldChange('down_payment_percent', newValue,100)}
                        min={0}
                        max={100}
                        step={0.01}
                        isPercentage={true} 
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <SliderInputField
                        label="Interest Rate(%)"
                        value={formData.interest_rate}
                        onChange={(newValue) => handleFieldChange('interest_rate', newValue,20)}
                        min={0}
                        max={20}
                        step={0.01}
                        isPercentage={true} 
                    />
                </Grid>
                {showbreakdown && (
                    <>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                fullWidth
                                label="Rate Buydown(Pay)"
                                variant="outlined"
                                size="small"
                                value={formData.rate_buydown_pay}
                                onChange={(e) => handleFieldChange('rate_buydown_pay', e.target.value)}
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                            <SliderInputField
                                label="Rate Buydown(Reduction%)"
                                value={formData.rate_buydown_reduction}
                                onChange={(newValue) => handleFieldChange('rate_buydown_reduction', newValue, formData.interest_rate)}
                                min={0}
                                max={formData.interest_rate}
                                step={0.01}
                                isPercentage={true}
                            />
                        </Grid>
                    </>
                )}

                <Grid item xs={12} sm={6}>
                    <SliderInputField
                        label="Property Tax(%)"
                        value={formData.property_tax_rate}
                        onChange={(newValue) => handleFieldChange('property_tax_rate', newValue,10)}
                        min={0}
                        max={10}
                        step={0.01}
                        isPercentage={true} 
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <SliderInputField
                        label="Home Insurance"
                        value={formData.insurance_cost}
                        onChange={(newValue) => handleFieldChange('insurance_cost', newValue,houseDetail.house_price ? houseDetail.house_price / 10 : 1000)}
                        min={0}
                        max={houseDetail.house_price ? houseDetail.house_price / 20 : 10000}
                        step={1} 
                        isPercentage={false} 
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Closing Cost(%)"
                        variant="outlined"
                        size="small"
                        value={formData.buying_closing_costs}
                        onChange={(e) => handleFieldChange('buying_closing_costs', e.target.value)}
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{ step: '0.01', min: 0, max: 100 }}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Condo/HOA Fees"
                        variant="outlined"
                        size="small"
                        value={formData.HOA}
                        onChange={(e) => handleFieldChange('condo_fees', e.target.value)}
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Home Appreciation(%)"
                        variant="outlined"
                        size="small"
                        value={formData.house_price_appreciation}
                        onChange={(e) => handleFieldChange('house_price_appreciation', e.target.value)}
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                        inputProps={{ step: '0.01', min: 0, max: 100 }}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        fullWidth
                        label="Additional Monthly Costs"
                        variant="outlined"
                        size="small"
                        value={formData.additional_monthly_costs}
                        onChange={(e) => handleFieldChange('additional_monthly_costs', e.target.value)}
                        type="number"
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth variant="outlined" size="small">
                        <InputLabel>Hold for (Years)</InputLabel>
                        <Select
                            value={formData.hold_for_years}
                            onChange={(e) => handleFieldChange('hold_for_years', e.target.value)}
                            label="Duration"
                        >
                            <MenuItem value={10}>10 Years</MenuItem>
                            <MenuItem value={15}>15 Years</MenuItem>
                            <MenuItem value={20}>20 Years</MenuItem>
                            <MenuItem value={30}>30 Years</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </div>
    );
}

export default RealStateSetting;
