import React, { useEffect, useState } from 'react';
import './ByvrChatBot.css';
import { IconButton, List, Button, Typography, TextField, Box, InputAdornment } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import aiService from '../../Services/aiService';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import ByvrAIResponse from './ByvrAIResponseView';
import LoadingComponent from "./ByvrLoadingAIResponse";


const ByvrChatBot = ({ toggleinfo, setToggleInfo, street_address, selectedAddress, google_place_id }) => {

    const suggestedQuestions = [
        "Is this house is good for raising kids?",
        "How are the schools in the neighbourhood?",
        "What's the weather like in this area?"
    ];
    const [userInput, setUserInput] = useState('');
    const [chatHistory, setChatHistory] = useState([]);
    const [loadingAIResp, setLoadingAIResp] = useState(false);

    const handleInputChange = (e) => {
        setUserInput(e.target.value);
    };

    const handleSend = async () => {
        if (userInput.trim()) {
            setLoadingAIResp(true);
            setChatHistory([...chatHistory, { sender: 'user', message: userInput }]);
            setUserInput('');

            try {
                const ai_response = await aiService.get_house_query_answer(userInput, selectedAddress, google_place_id);
                setLoadingAIResp(false);
                setChatHistory(prevHistory => [...prevHistory, { sender: 'ai', message: ai_response }]);
            } catch (error) {
                console.error("Error fetching AI response:", error);
                setLoadingAIResp(false);
                setChatHistory(prevHistory => [...prevHistory, { sender: 'ai', message: 'Sorry, something went wrong.' }]);
            }
        }
    };

    return (
        <div className="ba-we-love-subscribers-wrap">
            <div className={`ba-we-love-subscribers popup-ani ${toggleinfo ? 'open' : ''}`}
                style={{
                    padding: '5px',
                    display: 'flex',
                    flexDirection: 'column',
                }}>
                <Typography variant="h6" sx={{ margin: 2 }}>
                    BYVR AI : What Can I Help You With?
                </Typography>
                <Box sx={{ flex: 1, overflowY: 'auto', marginTop: 2 }}>
                    {chatHistory.map((chat, index) => (
                        <Box key={index} sx={{
                            marginBottom: 1,
                            display: 'flex',
                            justifyContent: chat.sender === 'user' ? 'flex-end' : 'flex-start'
                        }}>
                            <Box
                                sx={{
                                    padding: '8px',
                                    borderRadius: '8px',
                                    backgroundColor: chat.sender === 'user' ? '#cfe9ff' : '#e0e0e0',
                                    maxWidth: '90%',
                                    fontSize: '.75rem',
                                    wordWrap: 'break-word',
                                }}
                            >
                                {chat.sender === 'user' ? (
                                    <Typography variant="body1" sx={{}}><strong>You:</strong> {chat.message}</Typography>
                                ) : (<ByvrAIResponse response={chat.message}></ByvrAIResponse>)}
                            </Box>
                        </Box>
                    ))}
                    {loadingAIResp && <LoadingComponent />}
                </Box>

                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, marginBottom: 2 }}>
                    {suggestedQuestions.map((question, index) => (
                        <Button
                            key={index}
                            variant="outlined"
                            sx={{ borderRadius: '20px', textTransform: 'none', fontSize: '.6rem' }}
                            onClick={() => setUserInput(question)}
                        >
                            {question}
                        </Button>
                    ))}
                </Box>
                <TextField
                    value={userInput}
                    onChange={handleInputChange}
                    fullWidth
                    placeholder="Type your question..."
                    onKeyDown={(e) => {
                        if (e.key === "Enter" && toggleinfo) {
                            handleSend();
                        }
                    }}
                    sx={{
                        marginTop: 2,
                        bottom: 10
                    }}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={handleSend} color="primary">
                                    <SendIcon />
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                />
            </div>
            <div className="ba-we-love-subscribers-fab" onClick={() => setToggleInfo(!toggleinfo)}>
                <Box sx={{ position: 'fixed', bottom: 20, right: 20 }}>
                    <IconButton color="primary" sx={{
                        display: 'flex',
                        alignItems: 'center',
                        backgroundColor: '#80808042',
                        color: 'white',
                        borderRadius: '50px',
                        padding: '10px 20px',
                        '&:hover': {
                            backgroundColor: '#80808042',
                        },
                    }}>
                        {!toggleinfo && <span style={{ marginLeft: '10px', fontSize: '1rem' }}>What you want to know..</span>}
                        <div className={`wrap ${toggleinfo ? 'ani' : ''}`}>
                            <div className={`img-fab img ${toggleinfo ? 'close' : ''}`}></div>
                        </div>
                    </IconButton>
                </Box>

            </div>
        </div>
    );
};

export default ByvrChatBot;
