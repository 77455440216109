/* eslint-disable react/jsx-indent-props */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-props-no-spreading */
import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import parse from 'autosuggest-highlight/parse'; // Import the parse function
import { debounce } from '@mui/material/utils';
import './ByvrAddressSearchField.css';
import logger from '../utils/logger';
import authServices from '../Services/authService';
import{ useState, useEffect } from "react";

// This key was created specifically for the demo in mui.com.
// You need to create a new one for your application.
const GOOGLE_MAPS_API_KEY = 'AIzaSyD2ZbSEig980e93OperGJgA6oPInjMnHDI';
function getLocation() {
    return new Promise((resolve) => {
        if ("geolocation" in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    resolve({
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    });
                },
                (error) => {
                    resolve(null);
                }
            );
        } else {
            resolve(null);
        }
    });
}


async function loadScript(src, position, id) {
    if (!position) return;

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);

    const location = await getLocation();
    const lat = location?.lat ?? null;
    const lng = location?.lng ?? null;
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_MAPS_API_KEY}`;
    try {
        const response = await fetch(url);
        const data = await response.json();
        const addressComponents = data.results[0].address_components;
        const cityComponent = addressComponents.find(component =>
            component.types.includes("locality")
        );
        const city = cityComponent ? cityComponent.long_name : null;
        return city;
    } catch (error) {
        console.error("Error fetching address:", error);
        return null;
    }
}

const autocompleteService = { current: null };

export default function GoogleMaps({ userData }) {
    const [value, setValue] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const loaded = React.useRef(false);

    const navigate = useNavigate();

    const showAnalytics = (value) => {
        logger.debug(value);
        let navPath = '/analytics';
        if (!value.types.includes('street_address') && !value.types.includes('premise') && !value.types.includes('subpremise')) {
            navPath = '/bulk-analytics';
        }
        if (value) {
            navPath = `${navPath}?address=${value.description}&google_place_id=${value.place_id}`;
            if (navPath.includes('/bulk-analytics')) {
                navPath += '&page_number=1';
            }
        }
    
        navigate(navPath);
    };
    

    useEffect(() => {
        if (typeof window !== 'undefined' && !loaded.current) {
            if (!document.querySelector('#google-maps')) {
                loadScript(
                    `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`,
                    document.querySelector('head'),
                    'google-maps'
                ).then((response) => {
                    setInputValue(response);
                });
            }
            loaded.current = true;
        }
    }, []);

    const fetch = React.useMemo(
        () => debounce((request, callback) => {
            autocompleteService.current.getPlacePredictions(request, callback);
        }, 400),
        [],
    );

    const debouncedCheckUser = React.useMemo(
        () => debounce((userData) => {
            authServices.checkUser(userData);
        }, 500),
        []
    );

    React.useEffect(() => {
        let active = true;

        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }

        fetch(
            {
                input: inputValue,
                types: ['geocode'],
                componentRestrictions: {
                    country: 'US', // Restrict suggestions to the United States
                },
            },
            (results) => {
                if (active) {
                    let newOptions = [];

                    if (value) {
                        newOptions = [value];
                    }

                    if (results) {
                        newOptions = [...newOptions, ...results];
                    }

                    setOptions(newOptions);
                }
            },
        );

        return () => {
            active = false;
        };
    }, [value, inputValue, fetch]);

    return (
        <div className="search-boxs">
            <Autocomplete
                className="search-col"
                id="google-map-demo"
                getOptionLabel={(option) => (typeof option === 'string' ? option : option.description)}
                filterOptions={(x) => x}
                options={options}
                autoComplete
                includeInputInList
                filterSelectedOptions
                value={value}
                noOptionsText="No locations"
                onOpen={() => {
                    if (!userData) {
                        navigate('/login'); 
                    }
                }}
                onChange={(event, newValue) => {
                    console.log(newValue, "newValue=")
                    setOptions(newValue ? [newValue, ...options] : options);
                    setValue(newValue);
                    logger.debug('trigger search');
                    showAnalytics(newValue);
                }}
                onInputChange={(event, newInputValue) => {
                    if (!userData) {
                        navigate('/login'); // Navigate to login if user data does not exist
                    } else {
                        debouncedCheckUser(userData);
                        setInputValue(newInputValue);
                    }
                }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        className=" has-icon b-radius"
                        placeholder="Enter an address, neighborhood, city, or ZIP code"
                        fullWidth
                    />
                )}
                renderOption={(props, option) => {
                    const matches = option.structured_formatting.main_text_matched_substrings || [];

                    const parts = parse(
                        option.structured_formatting.main_text,
                        matches.map((match) => [match.offset, match.offset + match.length]),
                    );

                    return (
                        <li {...props} style={{ padding: '10px', borderBottom: '1px solid #ccc' }}>
                            <Grid container alignItems="center">
                                <Grid item sx={{ display: 'flex', width: 44 }}>
                                    <LocationOnIcon sx={{ color: 'text.secondary' }} />
                                </Grid>
                                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }}>
                                    {parts.map((part, index) => (
                                        <Box
                                            key={index}
                                            component="span"
                                            sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }}
                                        >
                                            {part.text}
                                        </Box>
                                    ))}
                                    <Typography variant="body2" color="text.secondary">
                                        {option.structured_formatting.secondary_text}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </li>
                    );
                }}
            />
            <div className="find">
                <button className="btn button-theme btn-search btn-block b-radius">
                    <i className="fa fa-search"></i><strong>Search</strong>
                </button>
            </div>
        </div>
    );
}
